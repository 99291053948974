<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-divider content-position="center" />
    <div class="my-20">
      本次投放手机号数量共10000个，其中成功3000个，失败7000个。
    </div>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="success" @click="handleCheckDetail">查看详情</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref } from 'vue'
  const props = defineProps({
    title: {
      type: String,
      default: '投放结果',
    },
  })
  const dialogFormVisible = ref(false)
  const state = reactive({
    form: {
      batchNo: '',
      file: null,
    },
  })
  // 关闭
  const handleClose = () => {
    dialogFormVisible.value = false
  }
  // 打开
  const handleOpen = (row = {}) => {
    state.form = row
    dialogFormVisible.value = true
  }
  // 查看详情
  const handleCheckDetail = () => {
    console.log('查看详情')
  }
  // 保存
  const handleSave = async () => {}
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
